import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from 'src/guards/admin.guard';
import { AnonymousGuard } from 'src/guards/anonymous.guard';
import { ChatGuard } from 'src/guards/chat.guard';
import { HrGuard } from 'src/guards/hr.guard';
import { LoggedInGuard } from 'src/guards/loggedIn.guard';
import { ValidCompanyGuard } from './customer/company/valid-company.guard';
import { DelayPreloadingStrategyService } from './delay-preloading-strategy.service';
import { canUseSalariesGuard } from './find-salaries/can-use-salaries.guard';

export const routes: Routes = [
  {
    path: 'client',
    loadChildren: () => import('./customer/customer.module').then((m) => m.CustomerModule),
    data: { preload: true },
  },
  {
    path: 'company',
    loadChildren: () =>
      import('./customer/company/company-careers/company-careers.module').then(
        (m) => m.CompanyCareersModule,
      ),
  },
  { path: 'register', redirectTo: 'skilled-people/building-profile' },
  {
    path: 'signup',
    loadChildren: () =>
      import('./shared/security/signup/signup.routes').then((m) => m.SignupRoutes),
    canLoad: [AnonymousGuard],
    data: { preload: true },
  },
  {
    path: 'activation',
    loadChildren: () =>
      import('./shared/security/activation/activation.module').then((m) => m.ActivationModule),
  },
  {
    path: 'linkedin/callback',
    loadChildren: () =>
      import('./shared/security/linkedin-callback/linkedin-callback.module').then(
        (m) => m.LinkedinCallbackModule,
      ),
  },
  {
    path: 'unsubscribe',
    loadChildren: () =>
      import('./core/unsubscribe/unsubscribe.module').then((m) => m.UnsubscribeModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./core/legal-pages/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule,
      ),
  },
  {
    path: 'cookies-policy',
    loadChildren: () =>
      import('./core/legal-pages/cookies-policy/cookies-policy.module').then(
        (m) => m.CookiesPolicyModule,
      ),
  },
  {
    path: 'terms',
    loadChildren: () => import('./core/legal-pages/terms/terms.module').then((m) => m.TermsModule),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canLoad: [AdminGuard],
  },
  {
    path: 'hr',
    loadChildren: () =>
      import('./hr/hr-dashboard/hr-dashboard.module').then((m) => m.HrDashboardModule),
    canLoad: [LoggedInGuard, HrGuard],
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then((m) => m.AnalyticsModule),
    canLoad: [AdminGuard],
  },
  {
    path: 'callback',
    loadChildren: () => import('./callbacks/callbacks.module').then((m) => m.CallbacksModule),
  },
  {
    path: 'messages',
    loadChildren: () => import('./chat/chat-page/chat-page.module').then((m) => m.ChatPageModule),
    canLoad: [LoggedInGuard, ChatGuard],
  },
  {
    path: 'find-salaries',
    loadChildren: () =>
      import('./find-salaries/find-salaries.module').then((m) => m.FindSalariesModule),
    canMatch: [ValidCompanyGuard, canUseSalariesGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./home-wrapper/home-wrapper.module').then((m) => m.HomeWrapperModule),
  },
  {
    path: '',
    loadChildren: () =>
      import('./skilled-people/skills-landing/skills-landing.module').then(
        (m) => m.SkillsLandingModule,
      ),
    data: { preload: true },
  },
  {
    path: '',
    loadChildren: () => import('./verticals/verticals.module').then((m) => m.VerticalsModule),
  },
  {
    path: 'loading',
    loadChildren: () => import('./loading/loading.module').then((m) => m.LoadingModule),
  },
  {
    path: 'not-found',
    loadChildren: () => import('./not-found/not-found.module').then((m) => m.NotFoundModule),
  },
  { path: 'jobs', redirectTo: 'skilled-people/find-work' },
  {
    path: 'storybook',
    loadChildren: () =>
      import('./core/storybook/storybook.component').then((m) => m.storybookRoutes),
    canMatch: [LoggedInGuard],
  },
  {
    path: 'testaawy',
    loadChildren: () => import('./core/test/test.module').then((m) => m.TestModule),
  },
  {
    path: 'assessments',
    loadChildren: () => import('./assessment/assessment.routes').then((m) => m.assessmentRoutes),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      preloadingStrategy: DelayPreloadingStrategyService,
      // enableTracing: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
