import { inject } from '@angular/core';
import type { CanMatchFn } from '@angular/router';
import { map } from 'rxjs';
import { JBPackageFeature } from 'src/models/JBPackage/JBPackage';
import { AuthenticationService } from 'src/services/authentication/authentication.service';
import { UpgradeNeededHandlerService } from '../customer/customer-dashboard/job-list-dashboard/job-board-packages-stepper/upgrade-needed-handler.service';

export const canUseSalariesGuard: CanMatchFn = (route, segments) => {
  const upgradeNeededHandler = inject(UpgradeNeededHandlerService); // inject your service
  const authService = inject(AuthenticationService); // inject your service

  return !authService.isLoggedIn()
    ? true
    : upgradeNeededHandler
        .checkAndHandleCurrentUserNeedPackageUpgrade(JBPackageFeature.ATS, 'Salary search feature')
        .pipe(map((needUpgrade) => !needUpgrade));
};
