import { Component, Input, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { Observable, first, map } from 'rxjs';
import { HelpChatBotService } from 'src/services/help-chat-bot.service';
import { SfBrowserUtilities } from 'src/services/sf-browser-utilities.service';
import { AuthenticationService } from '../../../../../services/authentication/authentication.service';
import { HeaderServices } from '../../header.service';
import { CompanyService } from './../../../../../models/company/company.service';

@Component({
  selector: 'app-header-customer-side-nav',
  templateUrl: './header-customer-side-nav.component.html',
  styleUrls: ['./header-customer-side-nav.component.scss'],
})
export class HeaderCustomerSideNavComponent implements OnInit {
  @Input() sidenav: MatSidenav;

  companyId$: Observable<number>;
  showPeople: boolean = true;

  constructor(
    public headerServices: HeaderServices,
    private companyService: CompanyService,
    private helpChatBotService: HelpChatBotService,
    private sfBrowserUtilities: SfBrowserUtilities,
    private authenticationService: AuthenticationService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.companyId$ = this.companyService
      .currentUserCompanyCached()
      .pipe(map((company) => company?.id));
    this.authenticationService
      .getUserInfo()
      .pipe(first())
      .subscribe((userInfo) => {
        if (userInfo.email == 'rocal48096@owube.com') {
          this.showPeople = false;
        }
      });
  }

  ngAfterViewInit(): void {}

  handleClick() {
    if (!this.headerServices.isSideNavSticky) this.sidenav.close();
  }

  openContactUs() {
    this.sidenav.close();
    this.helpChatBotService.openChat();
  }

  upgradePackage() {
    this.router.navigateByUrl('/client/pricing');
  }
}
