import { HTTP_INTERCEPTORS, provideHttpClient, withXsrfConfiguration } from '@angular/common/http';
import { APP_ID, Injector, NgModule } from '@angular/core';
import { XsrfInterceptor } from 'src/interceptors/XSRFInterciptor';
import { ErrorInterceptor } from 'src/interceptors/error.interceptor';
import { JwtInterceptor } from 'src/interceptors/jwt.interceptor';
import { LoadingInterceptor } from 'src/interceptors/loading.interceptor';
import { AppInjector } from 'src/services/app-injector.service';
import { CometChatBrowserService } from 'src/services/third-party/comet-chat-browser.service';
import { CometChatService } from 'src/services/third-party/comet-chat.service';
import { AppSharedModule } from './app-shared.module';
import { AppComponent } from './app.component';
import { MonacoEditorModule, NgxMonacoEditorConfig } from 'ngx-monaco-editor-v2';

const monacoConfig: NgxMonacoEditorConfig = {
  defaultOptions: {
    automaticLayout: 'true',
  },
  // onMonacoLoad: () => { console.log((<any>window).monaco.languages.registerCompletionItemProvider("cpp",)); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};

@NgModule({
  bootstrap: [AppComponent],
  imports: [AppSharedModule, MonacoEditorModule.forRoot(monacoConfig)],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: CometChatService, useClass: CometChatBrowserService },
    { provide: HTTP_INTERCEPTORS, useClass: XsrfInterceptor, multi: true },
    { provide: APP_ID, useValue: 'supportfinity' },
    provideHttpClient(
      withXsrfConfiguration({
        cookieName: 'csrf_token',
        headerName: 'csrf-token',
      }),
    ),
  ],
})
export class AppModule {
  constructor(injector: Injector) {
    AppInjector.injector = injector;
  }
}
